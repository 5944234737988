<template>
  <div class="dashboard">管理中心</div>
</template>

<script>
export default {
  name: 'Dashboard',
  components: {},
  data() {
    return {}
  },
  methods: {},
  computed: {},
  watch: {},
  mounted() {}
}
</script>

<style scoped>
.dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
